@import '~app/mixins';

.activity {
  display: flex;
}

.cLoader{
  width: 1160px;
  height: 658px;
  padding: 15px 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #5897F5;
  }
}
